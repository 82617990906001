import React from "react";

export default function Spinner(props) {
  return (
    <div className={`${props.centered && "center-spinner-box"}`}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

// If we want to use heart spinner later:
// <div className="lds-heart">
//   <div></div>
// </div>
