import { FunctionComponent, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";

import Home from "./Home";
import Pharmacy from "./Pharmacy";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path={"/:pharmacyId"} element={<Pharmacy />} />
      </Routes>
    </BrowserRouter>
  );
}
