import { FunctionComponent, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";

// blueberry npi: 1609401926

function adjustPrice() {}

export default function Pharmacy() {
  let { pharmacyId } = useParams();
  const [pharmacy, setPharmacy] = useState(undefined);
  const [drugSearch, setDrugSearch] = useState("");
  const [prices, setPrices] = useState([]);
  const [units, setUnits] = useState(30);
  const [priceLoadingError, setPriceLoadingError] = useState(false);

  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customUnits, setCustomUnits] = useState(undefined);
  const [quantityPerDay, setQuantityPerDay] = useState(undefined);
  const [activeToggle, setActiveToggle] = useState("30"); // '30', '90', or 'custom'

  const [customDaysSupply, setCustomDaysSupply] = useState(undefined);

  useEffect(() => {
    axios
      .get(`https://rxdeals-api-bgzwqgpyxq-uc.a.run.app/pharmacies`)
      .then((res) => {
        const data = res.data;
        // get the pharmacy or set null if not found
        setPharmacy(
          data.filter((x) => x.pharmacy_npi === pharmacyId).length > 0
            ? data.filter((x) => x.pharmacy_npi === pharmacyId)[0]
            : null
        );
      });
  }, []);

  useEffect(() => {
    if (pharmacy?.pharmacy_npi) {
      getPrices();
    }
  }, [pharmacy]);

  useEffect(() => {
    if (customUnits !== undefined && quantityPerDay !== undefined) {
      setCustomDaysSupply(customUnits / quantityPerDay);
    }
  }, [customUnits, quantityPerDay]);

  const getPrices = () => {
    axios
      .get(
        `https://rxdeals-api-bgzwqgpyxq-uc.a.run.app/prices/${pharmacy.pharmacy_npi}`
      )
      .then((res) => {
        const data = res.data;
        setPrices(data);
        // console.log(data);
      })
      .catch((e) => setPriceLoadingError(true));
  };

  if (pharmacy === undefined) {
    return <Spinner centered={true} />;
  }

  if (pharmacy === null) {
    return <p>Not Found</p>;
  }

  let displayPrices = <Spinner centered={false} />;

  if (prices.length > 0) {
    displayPrices = (
      <div>
        {prices
          .sort((a, b) => (a.drug_name > b.drug_name ? 1 : -1))
          .map((drug, i) => {
            let ndcWithNoDashes = drug.ndc?.replace(/-/g, "");
            ndcWithNoDashes = ndcWithNoDashes?.replace(/ /g, "0");
            // if ndc is less than 11 digits, add zeros to the front

            let applyPriceAdjustment = false;

            let ndcsThatNeedPriceAdjustment = [
              { ndc: "00054074287", packSize: 6.7 },
              { ndc: "00254100752", packSize: 6.7 },
              { ndc: "00781729685", packSize: 6.7 },
              { ndc: "00093317431", packSize: 8.5 },
              { ndc: "68180096301", packSize: 8.5 },
              { ndc: "66993001968", packSize: 18 },
              { ndc: "007817296", packSize: 6.7 },
              { ndc: "681800963", packSize: 8.5 },
            ];
            let advairNDCs = [
              "66993058497",
              "00093751831",
              "66993058597",
              "66993058697",
              "00054032656",
              "00054032756",
              "00093360982",
              "00093751731",
            ];

            let ndcsThatNeedPriceAdjustmentNonEachs = [
              {
                // bimataprost
                ndc: "007816206",
                packSize: 5,
                unit: "mL",
              },
              {
                ndc: "343598210",
                packSize: 50,
                unit: "gm",
              },
              {
                ndc: "516721407",
                packSize: 20,
                unit: "gm",
              },
              {
                ndc: "516721394",
                packSize: 20,
                unit: "gm",
              },
              {
                ndc: "516721395",
                packSize: 20,
                unit: "gm",
              },
              {
                ndc: "345802578",
                packSize: 2,
                unit: "ea",
              },
              {
                ndc: "370700164",
                packSize: 1,
                unit: "dose",
              },
              {
                ndc: "693670174",
                packSize: 28.35,
                unit: "gm",
              },
              {
                ndc: "009551004",
                packSize: 0.4,
                unit: "mL",
              },
              {
                ndc: "684620626",
                packSize: 50,
                unit: "gm",
              },
              {
                ndc: "000933608",
                packSize: 1,
                unit: "inhaler",
              },
              {
                ndc: "669930078",
                packSize: 10.6,
                unit: "grams",
              },
              { ndc: "64980040524", packSize: 240, unit: "mL" },
              { ndc: "68180096301", packSize: 8.5, unit: "gm" },
              { ndc: "00054074287", packSize: 6.7, unit: "gm" },
              { ndc: "76204020060", packSize: 180, unit: "mL" },
              { ndc: "66993001968", packSize: 18, unit: "gm" },
              { ndc: "64980034214", packSize: 4, unit: "ea" },
              { ndc: "45802049326", packSize: 385, unit: "gm" },
              { ndc: "00904598426", packSize: 226, unit: "gm" },
              { ndc: "42571016142", packSize: 20, unit: "ea" },
              { ndc: "42571016142", packSize: 20, unit: "ea" },
              { ndc: "00781613957", packSize: 75, unit: "mL" },
              { ndc: "00143988980", packSize: 80, unit: "mL" },
              { ndc: "00143988901", packSize: 100, unit: "mL" },
              { ndc: "65862007175", packSize: 75, unit: "mL" },
              { ndc: "65862007150", packSize: 50, unit: "mL" },
              { ndc: "00536515026", packSize: 45, unit: "gm" },
              { ndc: "60505083305", packSize: 30, unit: "mL" },
              { ndc: "00093202723", packSize: 15, unit: "mL" },
              { ndc: "00093202623", packSize: 15, unit: "mL" },
              { ndc: "00093202631", packSize: 15, unit: "mL" },
              { ndc: "70710146002", packSize: 30, unit: "mL" },
              { ndc: "59762313001", packSize: 22.5, unit: "mL" },
              { ndc: "50111078766", packSize: 6, unit: "ea" },
              { ndc: "45802010196", packSize: 60, unit: "gm" },
              { ndc: "35573045408", packSize: 237, unit: "gm" },
              { ndc: "00472038115", packSize: 15, unit: "gm" },
              { ndc: "00168004046", packSize: 45, unit: "gm" },
              { ndc: "72578009301", packSize: 15, unit: "gm" },
              { ndc: "51672131001", packSize: 15, unit: "gm" },
              { ndc: "51672131003", packSize: 50, unit: "gm" },
              { ndc: "69097032187", packSize: 60, unit: "mL" },
              { ndc: "60505612902", packSize: 8.43, unit: "mL" },
              { ndc: "00093413673", packSize: 100, unit: "mL" },
              { ndc: "00093413664", packSize: 60, unit: "mL" },
              { ndc: "68180072305", packSize: 100, unit: "mL" },
              { ndc: "00093413764", packSize: 60, unit: "mL" },
              { ndc: "00781320885", packSize: 1, unit: "mL" },
              { ndc: "00093417773", packSize: 100, unit: "mL" },
              { ndc: "00093417774", packSize: 200, unit: "mL" },
              { ndc: "00116200115", packSize: 15, unit: "mL" },
              { ndc: "00121089316", packSize: 473, unit: "mL" },
              { ndc: "61314065625", packSize: 2.5, unit: "mL" },
              { ndc: "43598032675", packSize: 7.5, unit: "mL" },
              { ndc: "51672125802", packSize: 30, unit: "gm" },
              { ndc: "00168025815", packSize: 15, unit: "gm" },
              { ndc: "00555904958", packSize: 28, unit: "ea" },
              { ndc: "69680011225", packSize: 1, unit: "mL" },
              { ndc: "64980050448", packSize: 120, unit: "mL" },
              { ndc: "72578008601", packSize: 15, unit: "gm" },
              { ndc: "45802049537", packSize: 60, unit: "gm" },
              { ndc: "24208072002", packSize: 5, unit: "mL" },
              { ndc: "64980050924", packSize: 60, unit: "mL" },
              { ndc: "45802005003", packSize: 28, unit: "gm" },
              { ndc: "24208045705", packSize: 5, unit: "mL" },
              { ndc: "00054062263", packSize: 473, unit: "mL" },
              { ndc: "00603116158", packSize: 473, unit: "mL" },
              { ndc: "61990041101", packSize: 4, unit: "mL" },
              { ndc: "61314001910", packSize: 10, unit: "mL" },
              { ndc: "68001046142", packSize: 10, unit: "mL" },
              { ndc: "71288043794", packSize: 8, unit: "mL" },
              { ndc: "68001046342", packSize: 10, unit: "mL" },
              { ndc: "62037086620", packSize: 10, unit: "mL" },
              { ndc: "68001045842", packSize: 4, unit: "mL" },
              { ndc: "68001045942", packSize: 6, unit: "mL" },
              { ndc: "54288012001", packSize: 10, unit: "mL" },
              { ndc: "24208091055", packSize: 3.5, unit: "gm" },
              { ndc: "70700011985", packSize: 28, unit: "ea" },
              { ndc: "51991062328", packSize: 28, unit: "ea" },
              { ndc: "00093354143", packSize: 42.5, unit: "gm" },
              { ndc: "00781713354", packSize: 4, unit: "ea" },
              { ndc: "00378335099", packSize: 4, unit: "ea" },
              { ndc: "68462071171", packSize: 8, unit: "ea" },
              { ndc: "00093767902", packSize: 1, unit: "ea" },
              { ndc: "00832604550", packSize: 50, unit: "mL" },
              { ndc: "57237014935", packSize: 35, unit: "mL" },
              { ndc: "54838052340", packSize: 120, unit: "mL" },
              { ndc: "00713068315", packSize: 15, unit: "gm" },
              { ndc: "24208058060", packSize: 5, unit: "mL" },
              { ndc: "68462073129", packSize: 28, unit: "ea" },
              { ndc: "68001057948", packSize: 1, unit: "mL" },
              { ndc: "70069003105", packSize: 1, unit: "mL" },
              { ndc: "00168014630", packSize: 28.35, unit: "gm" },
              { ndc: "51672300701", packSize: 10, unit: "mL" },
              { ndc: "24385002103", packSize: 28.4, unit: "gm" },
              { ndc: "51672300302", packSize: 28.35, unit: "gm" },
              { ndc: "62135075047", packSize: 120, unit: "mL" },
              { ndc: "54838051180", packSize: 473, unit: "mL" },
              { ndc: "33342015053", packSize: 1, unit: "ea" },
              { ndc: "55111057503", packSize: 1, unit: "ea" },
              { ndc: "60505403301", packSize: 120, unit: "ea" },
              { ndc: "45802036862", packSize: 24, unit: "gm" },
              { ndc: "00487980125", packSize: 62.5, unit: "mL" },
              { ndc: "76204010060", packSize: 150, unit: "mL" },
              { ndc: "76204060060", packSize: 180, unit: "mL" },
              { ndc: "70700011385", packSize: 28, unit: "ea" },
              { ndc: "00168009930", packSize: 30, unit: "gm" },
              { ndc: "00168009960", packSize: 60, unit: "gm" },
              { ndc: "00168009915", packSize: 15, unit: "gm" },
              { ndc: "45802046564", packSize: 120, unit: "mL" },
              { ndc: "42571013725", packSize: 5, unit: "mL" },
              { ndc: "72266011925", packSize: 2, unit: "mL" },
              { ndc: "00121087332", packSize: 946, unit: "mL" },
              { ndc: "57237027424", packSize: 240, unit: "mL" },
              { ndc: "59762033302", packSize: 2.5, unit: "mL" },
              { ndc: "61314054701", packSize: 2.5, unit: "mL" },
              { ndc: "00555901467", packSize: 28, unit: "ea" },
              { ndc: "00472023516", packSize: 473, unit: "mL" },
              { ndc: "31722057447", packSize: 473, unit: "mL" },
              { ndc: "52817083004", packSize: 118, unit: "mL" },
              { ndc: "70954013910", packSize: 118, unit: "mL" },
              { ndc: "00527194866", packSize: 100, unit: "mL" },
              { ndc: "68462067295", packSize: 91, unit: "ea" },
              { ndc: "68382071119", packSize: 120, unit: "ea" },
              { ndc: "42571036007", packSize: 473, unit: "mL" },
              { ndc: "68001000501", packSize: 21, unit: "ea" },
              { ndc: "00121057616", packSize: 120, unit: "mL" },
              { ndc: "00904541501", packSize: 2, unit: "ea" },
              { ndc: "00713025237", packSize: 45, unit: "gm" },
              { ndc: "00713063415", packSize: 15, unit: "gm" },
              { ndc: "68462018022", packSize: 22, unit: "gm" },
              { ndc: "24208079535", packSize: 3.5, unit: "gm" },
              { ndc: "24208063110", packSize: 10, unit: "mL" },
              { ndc: "24208063562", packSize: 10, unit: "mL" },
              { ndc: "68462063801", packSize: 100, unit: "ea" },
              { ndc: "68462063945", packSize: 25, unit: "ea" },
              { ndc: "00378728353", packSize: 28, unit: "ea" },
              { ndc: "68462013281", packSize: 28, unit: "ea" },
              { ndc: "68180087673", packSize: 28, unit: "ea" },
              { ndc: "68462056529", packSize: 28, unit: "ea" },
              { ndc: "00555901058", packSize: 28, unit: "ea" },
              { ndc: "00713067815", packSize: 15, unit: "gm" },
              { ndc: "00713067831", packSize: 60, unit: "gm" },
              { ndc: "72578008901", packSize: 15, unit: "gm" },
              { ndc: "68308015230", packSize: 30, unit: "gm" },
              { ndc: "68382037001", packSize: 15, unit: "gm" },
              { ndc: "00121086816", packSize: 60, unit: "mL" },
              { ndc: "70756060915", packSize: 5, unit: "mL" },
              { ndc: "70756061030", packSize: 10, unit: "mL" },
              { ndc: "70756060730", packSize: 5, unit: "mL" },
              { ndc: "70512052005", packSize: 5, unit: "mL" },
              { ndc: "64380076111", packSize: 120, unit: "ea" },
              { ndc: "21922002107", packSize: 60, unit: "gm" },
              { ndc: "61314020415", packSize: 15, unit: "mL" },
              { ndc: "61314062810", packSize: 10, unit: "mL" },
              { ndc: "61314063710", packSize: 10, unit: "mL" },
              { ndc: "61314063705", packSize: 5, unit: "mL" },
              { ndc: "00121075908", packSize: 60, unit: "mL" },
              { ndc: "00054372263", packSize: 60, unit: "mL" },
              { ndc: "43598021040", packSize: 400, unit: "gm" },
              { ndc: "67877012440", packSize: 25, unit: "gm" },
              { ndc: "65862014836", packSize: 9, unit: "ea" },
              { ndc: "55111029336", packSize: 9, unit: "ea" },
              { ndc: "55111029398", packSize: 9, unit: "ea" },
              { ndc: "55111029136", packSize: 9, unit: "ea" },
              { ndc: "62756052169", packSize: 9, unit: "ea" },
              { ndc: "55111029298", packSize: 9, unit: "ea" },
              { ndc: "51672137302", packSize: 30, unit: "gm" },
              { ndc: "51672130406", packSize: 45, unit: "gm" },
              { ndc: "51672130200", packSize: 20, unit: "gm" },
              { ndc: "61314022705", packSize: 5, unit: "mL" },
              { ndc: "61314022710", packSize: 10, unit: "mL" },
              { ndc: "70069013101", packSize: 5, unit: "mL" },
              { ndc: "00574403105", packSize: 5, unit: "mL" },
              { ndc: "00093214062", packSize: 28, unit: "ea" },
              { ndc: "45802005505", packSize: 454, unit: "gm" },
              { ndc: "00168000215", packSize: 15, unit: "gm" },
              { ndc: "67877025145", packSize: 45, unit: "gm" },
              { ndc: "52817081154", packSize: 454, unit: "gm" },
              { ndc: "67877025180", packSize: 80, unit: "gm" },
              { ndc: "00121065816", packSize: 60, unit: "mL" },
              { ndc: "00121067585", packSize: 473, unit: "mL" },
              { ndc: "69097014260", packSize: 6.7, unit: "gm" },
              { ndc: "52817081508", packSize: 236.5, unit: "mL" },
              { ndc: "70069009101", packSize: 6, unit: "mL" },
              { ndc: "00472038045", packSize: 45, unit: "gm" },
              { ndc: "00472038215", packSize: 15, unit: "gm" },
              { ndc: "68462029052", packSize: 50, unit: "gm" },
              { ndc: "51672126906", packSize: 45, unit: "gm" },
              { ndc: "61314014315", packSize: 15, unit: "ml" },
              { ndc: "00591242212", packSize: 15, unit: "ml" },
              { ndc: "00591242226", packSize: 5, unit: "ml" },
              { ndc: "00310737020", packSize: 10.2, unit: "gm" },
              { ndc: "00310737220", packSize: 10.2, unit: "gm" },
              { ndc: "00591215990", packSize: 120, unit: "ml" },
              { ndc: "59762374401", packSize: 30, unit: "gm" },
              { ndc: "21922002707", packSize: 60, unit: "gm" },
              { ndc: "51672408104", packSize: 60, unit: "ml" },
              { ndc: "21922002206", packSize: 45, unit: "gm" },
              { ndc: "00472040494", packSize: 118, unit: "ml" },
              { ndc: "68462060827", packSize: 50, unit: "gm" },
              { ndc: "21922001707", packSize: 50, unit: "gm" },
              { ndc: "51672129401", packSize: 15, unit: "gm" },
              { ndc: "68462053253", packSize: 50, unit: "mL" },
              { ndc: "69238153206", packSize: 60, unit: "gm" },
              { ndc: "00591350904", packSize: 4, unit: "ea" },
              { ndc: "00591351004", packSize: 4, unit: "ea" },
              { ndc: "00591350804", packSize: 4, unit: "ea" },
              { ndc: "61314023710", packSize: 10, unit: "mL" },
              { ndc: "00472080304", packSize: 118, unit: "mL" },
              { ndc: "21922000407", packSize: 60, unit: "gm" },
              { ndc: "51672135203", packSize: 60, unit: "gm" },
              { ndc: "00472047860", packSize: 60, unit: "gm" },
              { ndc: "00574016712", packSize: 112, unit: "gm" },
              { ndc: "68462035594", packSize: 100, unit: "gm" },
              { ndc: "21922000909", packSize: 100, unit: "gm" },
              { ndc: "42571014126", packSize: 10, unit: "mL" },
              { ndc: "24208048610", packSize: 10, unit: "mL" },
              { ndc: "42571038425", packSize: 60, unit: "mL" },
              { ndc: "00378336199", packSize: 4, unit: "ea" },
              { ndc: "00378464026", packSize: 8, unit: "ea" },
              { ndc: "65162098908", packSize: 8, unit: "ea" },
              { ndc: "65162099208", packSize: 8, unit: "ea" },
              { ndc: "65162099508", packSize: 8, unit: "ea" },
              { ndc: "65162099308", packSize: 8, unit: "ea" },
              { ndc: "00121067016", packSize: 473, unit: "mL" },
              { ndc: "45802048526", packSize: 118.28, unit: "mL" },
              { ndc: "00093026492", packSize: 60, unit: "gm" },
              { ndc: "64980045206", packSize: 60, unit: "mL" },
              { ndc: "51672138602", packSize: 30, unit: "gm" },
              { ndc: "16729054235", packSize: 40, unit: "gm" },
              { ndc: "00121072104", packSize: 120, unit: "mL" },
              { ndc: "00168033260", packSize: 60, unit: "gm" },
              { ndc: "00054327099", packSize: 16, unit: "gm" },
              { ndc: "00093751631", packSize: 60, unit: "ea" },
              { ndc: "00093751731", packSize: 60, unit: "ea" },
              { ndc: "00093751831", packSize: 60, unit: "ea" },
              { ndc: "66993008796", packSize: 12, unit: "gm" },
              { ndc: "66993008896", packSize: 12, unit: "gm" },
              { ndc: "66993008696", packSize: 12, unit: "gm" },
              { ndc: "45802013132", packSize: 50, unit: "gm" },
              { ndc: "00168035550", packSize: 50, unit: "gm" },
              { ndc: "00168002031", packSize: 28.35, unit: "gm" },
              { ndc: "45802043803", packSize: 28, unit: "gm" },
              { ndc: "62559043130", packSize: 30, unit: "gm" },
              { ndc: "21922000704", packSize: 15, unit: "gm" },
              { ndc: "68462053670", packSize: 24, unit: "ea" },
              { ndc: "00002822259", packSize: 15, unit: "mL" },
              { ndc: "00002773701", packSize: 10, unit: "mL" },
              { ndc: "24208039830", packSize: 30, unit: "mL" },
              { ndc: "00487980130", packSize: 75, unit: "mL" },
              { ndc: "60505082701", packSize: 15, unit: "mL" },
              { ndc: "69097084053", packSize: 90, unit: "mL" },
              { ndc: "00591405289", packSize: 45, unit: "gm" },
              { ndc: "00093321992", packSize: 60, unit: "gm" },
              { ndc: "60505100301", packSize: 5, unit: "mL" },
              { ndc: "70069042101", packSize: 2.5, unit: "mL" },
              { ndc: "47335093640", packSize: 2.8, unit: "mL" },
              { ndc: "00093414856", packSize: 90, unit: "mL" },
              { ndc: "00093414656", packSize: 90, unit: "mL" },
              { ndc: "69230032212", packSize: 120, unit: "mL" },
              { ndc: "00548540000", packSize: 1, unit: "mL" },
              { ndc: "45802013970", packSize: 70, unit: "gm" },
              { ndc: "61269073041", packSize: 45, unit: "gm" },
              { ndc: "60505083001", packSize: 17, unit: "gm" },
              { ndc: "68462038502", packSize: 60, unit: "mL" },
              { ndc: "68462019255", packSize: 45, unit: "gm" },
              { ndc: "45802011942", packSize: 45, unit: "gm" },
              { ndc: "00781713593", packSize: 3, unit: "mL" },
              { ndc: "45802001894", packSize: 30, unit: "gm" },
              { ndc: "00093216568", packSize: 2, unit: "ea" },
              { ndc: "24208083060", packSize: 5, unit: "mL" },
              { ndc: "00472016630", packSize: 30, unit: "gm" },
              { ndc: "60505056001", packSize: 10, unit: "mL" },
              { ndc: "60505056000", packSize: 5, unit: "mL" },
              { ndc: "58602000640", packSize: 5, unit: "mL" },
              { ndc: "54838055550", packSize: 50, unit: "mL" },
              { ndc: "00781627043", packSize: 250, unit: "mL" },
              { ndc: "60758090810", packSize: 10, unit: "mL" },
              { ndc: "42806009430", packSize: 30, unit: "ea" },
              { ndc: "00121094816", packSize: 473, unit: "mL" },
              { ndc: "00121075908", packSize: 237, unit: "mL" },
              { ndc: "68180079701", packSize: 460, unit: "mL" },
              { ndc: "00536199553", packSize: 207, unit: "mL" },
              { ndc: "46287002004", packSize: 118, unit: "mL" },
              { ndc: "00713067060", packSize: 60, unit: "gm" },
              { ndc: "51672208001", packSize: 15, unit: "gm" },
              { ndc: "64980051415", packSize: 15, unit: "mL" },
              { ndc: "64980051401", packSize: 10, unit: "mL" },
              { ndc: "64980051405", packSize: 5, unit: "mL" },
              { ndc: "60432056161", packSize: 60, unit: "mL" },
              { ndc: "60432056061", packSize: 60, unit: "mL" },
              { ndc: "67877025145", packSize: 454, unit: "gm" },
              { ndc: "67877031745", packSize: 454, unit: "gm" },
              { ndc: "00713065540", packSize: 5, unit: "gm" },
              { ndc: "52565001426", packSize: 454, unit: "gm" },
              { ndc: "33342033354", packSize: 454, unit: "gm" },
              { ndc: "67877031780", packSize: 80, unit: "gm" },
              { ndc: "45802006436", packSize: 80, unit: "gm" },
              { ndc: "52565005630", packSize: 30, unit: "gm" },
              { ndc: "67877031815", packSize: 15, unit: "gm" },
              { ndc: "45802005535", packSize: 15, unit: "gm" },
              { ndc: "51672128202", packSize: 30, unit: "gm" },
              { ndc: "67877031715", packSize: 15, unit: "gm" },
              { ndc: "50383079216", packSize: 473, unit: "mL" },
              { ndc: "71921017820", packSize: 120, unit: "ea" },
              { ndc: "69097022316", packSize: 4, unit: "ea" },
              { ndc: "69097022416", packSize: 4, unit: "ea" },
              { ndc: "00143988750", packSize: 100, unit: "ml" },
              { ndc: "00555904358", packSize: 28, unit: "ea" },
              { ndc: "59651021430", packSize: 30, unit: "ml" },
              { ndc: "70710146002", packSize: 30, unit: "ml" },
              { ndc: "51224002218", packSize: 6, unit: "ea" },
              { ndc: "00002771559", packSize: 15, unit: "ml" },
              { ndc: "00168005760", packSize: 60, unit: "ml" },
              { ndc: "51672134003", packSize: 60, unit: "ml" },
              { ndc: "45802005302", packSize: 100, unit: "gm" },
              { ndc: "45802005301", packSize: 50, unit: "gm" },
              { ndc: "60505058304", packSize: 2.5, unit: "ml" },
              { ndc: "60505058301", packSize: 5, unit: "ml" },
              { ndc: "00378750332", packSize: 120, unit: "ea" },
              { ndc: "00378750232", packSize: 120, unit: "ea" },
              { ndc: "68462031629", packSize: 28, unit: "ea" },
              { ndc: "00186077660", packSize: 60, unit: "ea" },
              { ndc: "00186077760", packSize: 60, unit: "ea" },
              { ndc: "61314014310", packSize: 10, unit: "ml" },
              { ndc: "66993093861", packSize: 60, unit: "g" },
              { ndc: "66993093865", packSize: 100, unit: "g" },
              { ndc: "16729007212", packSize: 60, unit: "ea" },
              { ndc: "72205000792", packSize: 120, unit: "ea" },
              { ndc: "65862021860", packSize: 60, unit: "ml" },
              { ndc: "65862021960", packSize: 60, unit: "ml" },
              { ndc: "00116200116", packSize: 473, unit: "ml" },
              { ndc: "21922005351", packSize: 6.6, unit: "ml" },
              { ndc: "62756042790", packSize: 7.5, unit: "ml" },
              { ndc: "69315030805", packSize: 5, unit: "ml" },
              { ndc: "59762500901", packSize: 40, unit: "g" },
              { ndc: "45802096126", packSize: 118, unit: "ml" },
              { ndc: "16571081450", packSize: 51, unit: "g" },
              { ndc: "51672135802", packSize: 150, unit: "ml" },
              { ndc: "45802011101", packSize: 100, unit: "g" },
              { ndc: "71921022610", packSize: 10, unit: "ml" },
              { ndc: "00003089321", packSize: 60, unit: "ea" },
              { ndc: "00003089421", packSize: 60, unit: "ea" },
              { ndc: "00078065920", packSize: 60, unit: "ea" },
              { ndc: "00093598627", packSize: 2, unit: "ea" },
              { ndc: "45802009735", packSize: 42.5, unit: "g" },
              { ndc: "31722006331", packSize: 50, unit: "ml" },
              { ndc: "60505082901", packSize: 16, unit: "ml" },
              { ndc: "66993007996", packSize: 12, unit: "g" },
              { ndc: "43386009019", packSize: 4000, unit: "ml" },
              { ndc: "00002751001", packSize: 10, unit: "ml" },
              { ndc: "63646001004", packSize: 118, unit: "ml" },
              { ndc: "42806040021", packSize: 21, unit: "ea" },
              { ndc: "72578012908", packSize: 45, unit: "g" },
              { ndc: "51672131201", packSize: 15, unit: "g" },
              { ndc: "42806055212", packSize: 120, unit: "ea" },
              { ndc: "00169418113", packSize: 2, unit: "ml" },
              { ndc: "65862087011", packSize: 1, unit: "ea" },
              { ndc: "69367031201", packSize: 100, unit: "ml" },
              { ndc: "61314022605", packSize: 5, unit: "ml" },
            ];

            let pharmacySystem = "";
            let productData = {};
            // SYSTEM 1: if unit cost is > $6 then 1 unit = 1 inhaler
            // SYSTEM 2: if unit cost is < $6 then 1 unit = 1 gram
            // SYSTEM 3: if Advairs
            // SYSTEM 4: if NDC matches multiple by pack size instead of 30/90
            if (drug.cost > 6) {
              pharmacySystem = "1";
            }
            if (drug.cost <= 6) {
              pharmacySystem = "2";
            }
            if (advairNDCs.includes(ndcWithNoDashes)) {
              pharmacySystem = "3";
              applyPriceAdjustment = true;
            }

            if (
              ndcsThatNeedPriceAdjustmentNonEachs.filter(
                (n) => n.ndc === ndcWithNoDashes
              ).length > 0
            ) {
              pharmacySystem = "4";
              applyPriceAdjustment = true;
              productData = ndcsThatNeedPriceAdjustmentNonEachs.filter(
                (n) => n.ndc === ndcWithNoDashes
              )[0];
            }

            // Check if price adjustment should be applied
            if (ndcWithNoDashes !== undefined) {
              if (
                ndcsThatNeedPriceAdjustment.filter(
                  (n) => n.ndc === ndcWithNoDashes
                ).length > 0
              ) {
                applyPriceAdjustment = true;
              }
            }

            let costPlusAmount = 0;
            let memberCostPlusAmount = 0;

            let regularPrice = 0;
            let memberPrice = 0;

            if (units === 30 || customDaysSupply <= 30) {
              // first check if this is a medication that a price adjustment should be applied to
              if (applyPriceAdjustment) {
                if (pharmacySystem === "1") {
                  costPlusAmount = pharmacy.cost_plus_amount_for_30_unit_supply;
                  // get unit cost
                  regularPrice = drug.cost * 1;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }
                if (pharmacySystem === "2") {
                  costPlusAmount = pharmacy.cost_plus_amount_for_30_unit_supply;
                  // get unit cost
                  regularPrice =
                    drug.cost *
                    ndcsThatNeedPriceAdjustment.filter(
                      (n) => n.ndc === ndcWithNoDashes
                    )[0].packSize;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }
                if (pharmacySystem === "3") {
                  costPlusAmount = pharmacy.cost_plus_amount_for_30_unit_supply;
                  // get unit cost
                  regularPrice = drug.cost * units * 2;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }
                if (pharmacySystem === "4") {
                  costPlusAmount = pharmacy.cost_plus_amount_for_30_unit_supply;
                  // get unit cost
                  regularPrice =
                    drug.cost *
                    ndcsThatNeedPriceAdjustmentNonEachs.filter(
                      (n) => n.ndc === ndcWithNoDashes
                    )[0].packSize;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }
                // else run the formula like normal
              } else {
                costPlusAmount = pharmacy.cost_plus_amount_for_30_unit_supply;
                // get unit cost
                regularPrice =
                  drug.cost * (customDaysSupply ? customUnits : units);

                // add margin
                regularPrice =
                  regularPrice *
                  (1 + parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                // add dispensing fee
                regularPrice = regularPrice + parseFloat(costPlusAmount);
              }

              if (pharmacy.member_price === "Yes") {
                memberCostPlusAmount =
                  pharmacy.member_cost_plus_amount_for_30_unit_supply;
                // first check if this is a medication that a price adjustment should be applied to
                if (applyPriceAdjustment) {
                  if (pharmacySystem === "1") {
                    // get unit cost
                    memberPrice = drug.cost * 1;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  if (pharmacySystem === "2") {
                    // get unit cost
                    memberPrice =
                      drug.cost *
                      ndcsThatNeedPriceAdjustment.filter(
                        (n) => n.ndc === ndcWithNoDashes
                      )[0].packSize;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);

                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  if (pharmacySystem === "3") {
                    memberCostPlusAmount =
                      pharmacy.member_cost_plus_amount_for_30_unit_supply;

                    // get unit cost
                    memberPrice = drug.cost * units * 2;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  if (pharmacySystem === "4") {
                    memberCostPlusAmount =
                      pharmacy.member_cost_plus_amount_for_30_unit_supply;

                    // get unit cost
                    memberPrice =
                      drug.cost *
                      ndcsThatNeedPriceAdjustmentNonEachs.filter(
                        (n) => n.ndc === ndcWithNoDashes
                      )[0].packSize;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  // else run the formula like normal
                } else {
                  memberCostPlusAmount =
                    pharmacy.member_cost_plus_amount_for_30_unit_supply;

                  // get unit cost
                  memberPrice =
                    drug.cost * (customDaysSupply ? customUnits : units);
                  // add margin
                  memberPrice =
                    memberPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  memberPrice = memberPrice + parseFloat(memberCostPlusAmount);
                }
              }
            }
            if (units === 90 || customDaysSupply > 30) {
              if (applyPriceAdjustment) {
                if (pharmacySystem === "1") {
                  costPlusAmount =
                    pharmacy.cost_plus_amount_for_90_units_supply;
                  // get unit cost
                  regularPrice = drug.cost * 3;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }
                if (pharmacySystem === "2") {
                  costPlusAmount =
                    pharmacy.cost_plus_amount_for_90_units_supply;
                  // get unit cost
                  regularPrice =
                    drug.cost *
                    ndcsThatNeedPriceAdjustment.filter(
                      (n) => n.ndc === ndcWithNoDashes
                    )[0].packSize *
                    3;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }
                if (pharmacySystem === "3") {
                  costPlusAmount =
                    pharmacy.cost_plus_amount_for_90_units_supply;
                  // get unit cost
                  regularPrice = drug.cost * units * 2;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }
                if (pharmacySystem === "4") {
                  costPlusAmount =
                    pharmacy.cost_plus_amount_for_90_units_supply;
                  // get unit cost
                  regularPrice =
                    drug.cost *
                    ndcsThatNeedPriceAdjustmentNonEachs.filter(
                      (n) => n.ndc === ndcWithNoDashes
                    )[0].packSize *
                    3;
                  // add margin
                  regularPrice =
                    regularPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  regularPrice = regularPrice + parseFloat(costPlusAmount);
                }

                // else run the formula like normal
              } else {
                costPlusAmount = pharmacy.cost_plus_amount_for_90_units_supply;
                // get unit cost
                regularPrice =
                  drug.cost * (customDaysSupply ? customUnits : units);
                // add margin
                regularPrice =
                  regularPrice *
                  (1 + parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                // add dispensing fee
                regularPrice = regularPrice + parseFloat(costPlusAmount);
              }

              if (pharmacy.member_price === "Yes") {
                if (applyPriceAdjustment) {
                  if (pharmacySystem === "1") {
                    memberCostPlusAmount =
                      pharmacy.member_cost_plus_amount_for_90_units_supply;
                    // get unit cost
                    memberPrice = drug.cost * 3;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  if (pharmacySystem === "2") {
                    memberCostPlusAmount =
                      pharmacy.member_cost_plus_amount_for_90_units_supply;
                    // get unit cost
                    memberPrice =
                      drug.cost *
                      ndcsThatNeedPriceAdjustment.filter(
                        (n) => n.ndc === ndcWithNoDashes
                      )[0].packSize *
                      3;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  if (pharmacySystem === "3") {
                    memberCostPlusAmount =
                      pharmacy.member_cost_plus_amount_for_90_units_supply;

                    // get unit cost
                    memberPrice = drug.cost * units * 2;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  if (pharmacySystem === "4") {
                    memberCostPlusAmount =
                      pharmacy.member_cost_plus_amount_for_90_units_supply;

                    // get unit cost
                    memberPrice =
                      drug.cost *
                      ndcsThatNeedPriceAdjustmentNonEachs.filter(
                        (n) => n.ndc === ndcWithNoDashes
                      )[0].packSize *
                      3;
                    // add margin
                    memberPrice =
                      memberPrice *
                      (1 +
                        parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                    // add dispensing fee
                    memberPrice =
                      memberPrice + parseFloat(memberCostPlusAmount);
                  }
                  // else run the formula like normal
                } else {
                  memberCostPlusAmount =
                    pharmacy.member_cost_plus_amount_for_90_units_supply;

                  // get unit cost
                  memberPrice =
                    drug.cost * (customDaysSupply ? customUnits : units);
                  // add margin
                  memberPrice =
                    memberPrice *
                    (1 +
                      parseFloat(pharmacy.margin_applied_to_unit_cost) / 100);
                  // add dispensing fee
                  memberPrice = memberPrice + parseFloat(memberCostPlusAmount);
                }
              }
            }

            return (
              <div
                key={`key-${drug.drug_name}-${i}`}
                className="priceRow"
                onMouseEnter={() => {
                  if (pharmacy.action_button_target_url?.length > 0) {
                    document
                      .getElementById(`id-${drug.ndc}-${i}`)
                      .classList.add("open");
                  }
                }}
                onMouseLeave={() => {
                  document
                    .getElementById(`id-${drug.ndc}-${i}`)
                    .classList.remove("open");
                }}
              >
                <div
                  className="row no-gutters"
                  style={{
                    display: drug.drug_name
                      .toLowerCase()
                      .includes(drugSearch.toLowerCase())
                      ? "flex"
                      : "none",
                  }}
                >
                  <div className="col-md-6 text-left p-3">
                    <p
                      className="drugName"
                      style={{
                        color: `#${pharmacy.primary_brand_color_hex}`,
                      }}
                    >
                      {drug.drug_name}
                    </p>
                    {/* <p className="ndc">{drug.ndc}</p> */}
                    <p className="ndc">{ndcWithNoDashes}</p>
                    {/* <p>{drug.cost}</p> */}
                  </div>
                  <div className="col-md-6 p-3">
                    <div className="drug-price-box">
                      {pharmacy.member_price === "Yes" ? (
                        <div className="membership-pricing-box">
                          <div className="member-price">
                            <p
                              className="member-price-text"
                              style={{
                                color: `#${pharmacy.primary_brand_color_hex}`,
                              }}
                            >
                              Member price: ${memberPrice.toFixed(2)}
                            </p>
                            {applyPriceAdjustment && pharmacySystem === "4" ? (
                              <p className="unit-text">
                                {units === 30
                                  ? `One pack of ${productData.packSize}`
                                  : `Three packs of ${productData.packSize}`}{" "}
                                {`${productData.unit}`}
                              </p>
                            ) : applyPriceAdjustment ? (
                              <p className="unit-text">
                                {units === 30 ? "1" : "3"} inhaler
                              </p>
                            ) : (
                              <p className="unit-text">
                                {customDaysSupply ? customUnits : units} units
                              </p>
                            )}
                          </div>
                          <div
                            className="non-member-price"
                            style={{
                              display:
                                pharmacy.cost_plus_amount_for_30_unit_supply ===
                                null
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <p className="non-member-price-text">
                              Non-member price: ${regularPrice.toFixed(2)}
                            </p>
                            {applyPriceAdjustment && pharmacySystem === "4" ? (
                              <p className="unit-text">
                                {units === 30
                                  ? `One pack of ${productData.packSize}`
                                  : `Three packs of ${productData.packSize}`}{" "}
                                {`${productData.unit}`}
                              </p>
                            ) : applyPriceAdjustment ? (
                              <p className="unit-text">
                                {units === 30 ? "1" : "3"} inhaler
                              </p>
                            ) : (
                              <p className="unit-text">
                                {customDaysSupply ? customUnits : units} units
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="pricing-box">
                          <div className="non-member-price">
                            <p className="non-member-price-text">
                              Price: ${regularPrice.toFixed(2)}
                            </p>
                            {applyPriceAdjustment && pharmacySystem === "4" ? (
                              <p className="unit-text">
                                {units === 30
                                  ? `One pack of ${productData.packSize}`
                                  : `Three packs of ${productData.packSize}`}{" "}
                                {`${productData.unit}`}
                              </p>
                            ) : applyPriceAdjustment ? (
                              <p className="unit-text">
                                {units === 30 ? "1" : "3"} inhaler
                              </p>
                            ) : (
                              <p className="unit-text">
                                {customDaysSupply ? customUnits : units} units
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="order-row" id={`id-${drug.ndc}-${i}`}>
                  <div className="text-center pb-3">
                    <a
                      href={
                        pharmacy.action_button_target_url?.length > 0
                          ? pharmacy.action_button_target_url.includes("http")
                            ? pharmacy.action_button_target_url
                            : `https://${pharmacy.action_button_target_url}`
                          : pharmacy.pharmacy_website.includes("http")
                          ? pharmacy.pharmacy_website
                          : `https://${pharmacy.pharmacy_website}`
                      }
                    >
                      <button
                        className="btn btn-primary order-button"
                        style={{
                          backgroundColor: `#${pharmacy.primary_brand_color_hex}`,
                          border: "none",
                        }}
                      >
                        {pharmacy.action_button_text?.length > 0
                          ? pharmacy.action_button_text
                          : "Order Medication"}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        {prices.filter((drug) =>
          drug.drug_name.toLowerCase().includes(drugSearch.toLowerCase())
        ).length === 0 && (
          <p>
            No results{" "}
            {`${
              prices.filter((drug) =>
                drug.drug_name.toLowerCase().includes(drugSearch.toLowerCase)
              ).length
            }`}
          </p>
        )}
      </div>
    );
  }

  if (priceLoadingError) {
    displayPrices = "No prices available. Check back soon!";
  }

  let logoSrc = "";
  if (pharmacy.pharmacy_logo.includes("?id=")) {
    logoSrc = `https://drive.google.com/thumbnail?id=${
      pharmacy.pharmacy_logo.split("?id=")[1]
    }&sz=w200`;
  }

  if (pharmacy.pharmacy_logo.includes("/file/d/")) {
    logoSrc = `https://drive.google.com/thumbnail?id=${
      pharmacy.pharmacy_logo.split("/file/d/")[1].split("/view?")[0]
    }&sz=w200`;
  }

  console.log(pharmacy.pharmacy_logo);

  // logoSrc = pharmacy.pharmacy_logo;

  // console.log(pharmacy);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const embed = urlParams.get("embed");

  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-lg-6 text-center mt-4 m-auto">
          <div
            className="row no-gutters"
            style={{ display: embed === "true" ? "none" : "flex" }}
          >
            <div
              className="col-lg-2 text-center pb-4 pl-3 pt-4 pharmacy-logo-box-column"
              style={{
                backgroundColor: `#${pharmacy.primary_brand_color_hex}`,
              }}
            >
              <div
                className="pharmacy-logo-box"
                style={{
                  borderColor: `#${pharmacy.primary_brand_color_hex}`,
                }}
              >
                <img src={logoSrc} alt="" />
              </div>
            </div>
            <div className="col-lg-8 pb-4 pt-4">
              <div className="row no-gutters">
                <div className="col-md-12 text-left pl-4">
                  <h1
                    style={{
                      color: `#${pharmacy.primary_brand_color_hex}`,
                      border: "none",
                    }}
                  >
                    {pharmacy.pharmacy_name}
                  </h1>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-12 text-left pl-4">
                  <div className="address-box">
                    <p>{`${pharmacy?.pharmacy_address_line_1}, ${pharmacy?.city} ${pharmacy?.state} ${pharmacy?.zip}`}</p>
                  </div>
                  <div className="contact-box">
                    <p
                      className="pharmacy-website"
                      style={{
                        color: `#${pharmacy.primary_brand_color_hex}`,
                        border: "none",
                      }}
                    >
                      <a
                        href={`${
                          pharmacy.pharmacy_website.includes("https://")
                            ? `${pharmacy.pharmacy_website}`
                            : `https://${pharmacy.pharmacy_website}`
                        }`}
                        className="pharmacy-website"
                        target="_blank"
                        style={{
                          color: `#${pharmacy.primary_brand_color_hex}`,
                          border: "none",
                        }}
                      >
                        <i className="fa fa-link"></i> Website
                      </a>
                    </p>
                    <p
                      className="pharmacy-phone"
                      style={{
                        color: `#${pharmacy.primary_brand_color_hex}`,
                        border: "none",
                      }}
                    >
                      <a
                        href={`tel:${pharmacy.pharmacy_phone}`}
                        style={{
                          color: `#${pharmacy.primary_brand_color_hex}`,
                          border: "none",
                        }}
                      >
                        <i className="fa fa-mobile"></i>{" "}
                        {pharmacy.pharmacy_phone}
                      </a>
                    </p>
                    <p
                      className="pharmacy-social"
                      style={{
                        color: `#${pharmacy.primary_brand_color_hex}`,
                        border: "none",
                        display:
                          pharmacy.facebook_url === null ? "none" : "block",
                      }}
                    >
                      <a
                        href={`${pharmacy.facebook_url}`}
                        target="_blank"
                        style={{
                          color: `#${pharmacy.primary_brand_color_hex}`,
                          border: "none",
                        }}
                      >
                        <i className="fa fa-facebook-square"></i>{" "}
                      </a>
                    </p>
                    <p
                      className="pharmacy-social"
                      style={{
                        color: `#${pharmacy.primary_brand_color_hex}`,
                        border: "none",
                        display:
                          pharmacy.instagram_url === null ? "none" : "block",
                      }}
                    >
                      <a
                        href={`${pharmacy.instagram_url}`}
                        target="_blank"
                        style={{
                          color: `#${pharmacy.primary_brand_color_hex}`,
                          border: "none",
                        }}
                      >
                        <i className="fa fa-instagram"></i>{" "}
                      </a>
                    </p>
                    <p
                      className="pharmacy-social"
                      style={{
                        color: `#${pharmacy.primary_brand_color_hex}`,
                        border: "none",
                        display:
                          pharmacy.twitter_url === null ? "none" : "block",
                      }}
                    >
                      <a
                        href={`${pharmacy.twitter_url}`}
                        target="_blank"
                        style={{
                          color: `#${pharmacy.primary_brand_color_hex}`,
                          border: "none",
                        }}
                      >
                        <i className="fa fa-twitter"></i>{" "}
                      </a>
                    </p>
                    <p
                      className="pharmacy-social"
                      style={{
                        color: `#${pharmacy.primary_brand_color_hex}`,
                        border: "none",
                        display:
                          pharmacy.linkedin_url === null ? "none" : "block",
                      }}
                    >
                      <a
                        href={`${pharmacy.linkedin_url}`}
                        target="_blank"
                        style={{
                          color: `#${pharmacy.primary_brand_color_hex}`,
                          border: "none",
                        }}
                      >
                        <i className="fa fa-linkedin"></i>{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters pt-3">
        <div className="col-md-6 m-auto">
          <div className="priceSearchInputContainer">
            {drugSearch.length > 0 ? (
              <i
                className="fa fa-times-circle price-search-icon searchX"
                onClick={() => setDrugSearch("")}
              ></i>
            ) : (
              <i className="fa fa-search price-search-icon"></i>
            )}
            <input
              type="text"
              className="priceSearchInput"
              placeholder="Search medications..."
              spellCheck={false}
              autoComplete="off"
              onChange={(e) => setDrugSearch(e.target.value)}
              value={drugSearch}
            />
          </div>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-md-12 pt-2 pb-2">
          <div className="unitsToggleContainer">
            <div
              className={`unitsToggle ${activeToggle === "30" && "active"}`}
              onClick={() => {
                setUnits(30);
                setActiveToggle("30");
                setShowCustomInput(false);
                setCustomDaysSupply(undefined);
                setCustomUnits(undefined);
                setQuantityPerDay(undefined);
              }}
              style={{
                backgroundColor: `${
                  activeToggle === "30"
                    ? `#${pharmacy.primary_brand_color_hex}`
                    : "white"
                }`,
                fontSize: showCustomInput ? "12px" : "15px",
                width: showCustomInput ? "15%" : "33%",
                transition: "width 0.3s ease",
              }}
            >
              30 Count
            </div>
            <div
              className={`unitsToggle ${activeToggle === "90" && "active"}`}
              onClick={() => {
                setUnits(90);
                setActiveToggle("90");
                setShowCustomInput(false);
                setCustomDaysSupply(undefined);
                setCustomUnits(undefined);
                setQuantityPerDay(undefined);
              }}
              style={{
                backgroundColor: `${
                  activeToggle === "90"
                    ? `#${pharmacy.primary_brand_color_hex}`
                    : "white"
                }`,
                fontSize: showCustomInput ? "12px" : "15px",
                width: showCustomInput ? "15%" : "33%",
                transition: "width 0.3s ease",
              }}
            >
              90 Count
            </div>
            <div
              className={`unitsToggle`}
              onClick={() => {
                setShowCustomInput(true);
                setActiveToggle("custom");
              }}
              style={{
                backgroundColor: `${"white"}`,
                border: `solid 2px ${
                  activeToggle === "custom"
                    ? `#${pharmacy.primary_brand_color_hex}`
                    : "white"
                }`,
                width: showCustomInput ? "70%" : "33%",
                transition: "width 0.3s ease",
              }}
            >
              {showCustomInput ? (
                <div
                  style={{ display: "flex", gap: "4px", alignItems: "center" }}
                >
                  <input
                    type="number"
                    value={customUnits}
                    placeholder="Total Quantity"
                    onChange={(e) => {
                      setCustomUnits(e.target.value);
                    }}
                    onClick={(e) => e.stopPropagation()}
                    className="customUnitsInput"
                    style={{
                      width: "35%",
                      border: "none",
                      textAlign: "center",
                      background: "transparent",
                      fontSize: "14px",
                    }}
                  />
                  <span style={{ fontSize: "14px" }}> | </span>
                  <input
                    type="number"
                    className="customUnitsInput"
                    value={quantityPerDay}
                    placeholder="Quantity Taken Per Day"
                    onChange={(e) => {
                      setQuantityPerDay(e.target.value);
                    }}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      width: "65%",
                      border: "none",
                      textAlign: "center",
                      background: "transparent",
                      fontSize: "14px",
                    }}
                  />
                </div>
              ) : (
                "Custom"
              )}
            </div>
          </div>
        </div>
      </div>
      <div>{/* <div>Custom Days Supply: {customDaysSupply}</div> */}</div>
      <div className="row no-gutters">
        <div className="col-md-6 m-auto text-center">
          <p className="pharmacyDisclaimer">
            Prices are based on best-available data, but may be subject to
            change at the discretion of each individual pharmacy.
          </p>
        </div>
      </div>

      <div className="row no-gutters pb-4">
        <div className="col-md-6 text-center m-auto">{displayPrices}</div>
      </div>
    </div>
  );
}
